import React from 'react';
import './PhotosPage.css'
import AWS from 'aws-sdk';

export class PhotosPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            currentDivision: "rpcpa",
            rpcImageUrls: [],
            rpcpaImageUrls: [],
            dulaangImageUrls: []
        }

        this.setUrls()
    }

    setUrls() {
        const S3_BUCKET_NAME = 'rpc-media';
        const REGION = 'us-east-1';
        // Configure AWS SDK with the region
        AWS.config.update({
        accessKeyId: "AKIAVGM2RA7KJK3T325P",
        secretAccessKey: "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",
        region: REGION
      });
  
      // Create an instance of the S3 service
      const s3 = new AWS.S3();
  
      // Function to list all files in the S3 bucket
      const getUrls = async () => {
        try {
          const params = {
            Bucket: S3_BUCKET_NAME,
            Prefix: `rpc/`
          };
          
          const response = await s3.listObjectsV2(params).promise();
          const urls = response.Contents
            .filter(file => file.Key.endsWith('.jpg') || file.Key.endsWith('.jpeg') || file.Key.endsWith('.png') || file.Key.endsWith('.JPG')) // Filter only image files
            .map(file => {
              return `https://${S3_BUCKET_NAME}.s3.${REGION}.amazonaws.com/${file.Key}`;
            });
            
          this.setState({ rpcImageUrls: urls })
          console.log(this.state.rpcImageUrls)
        } catch (error) {
          console.log('Error listing files:', error);
        }

        try {
            const params = {
              Bucket: S3_BUCKET_NAME,
              Prefix: `rpcpa/`
            };
            
            const response = await s3.listObjectsV2(params).promise();
            const urls = response.Contents
              .filter(file => file.Key.endsWith('.jpg') || file.Key.endsWith('.jpeg') || file.Key.endsWith('.png') || file.Key.endsWith('.JPG')) // Filter only image files
              .map(file => {
                return `https://${S3_BUCKET_NAME}.s3.${REGION}.amazonaws.com/${file.Key}`;
              });
              
            this.setState({ rpcpaImageUrls: urls })
            console.log(urls)
          } catch (error) {
            console.log('Error listing files:', error);
        }

        try {
            const params = {
              Bucket: S3_BUCKET_NAME,
              Prefix: `dulaang/`
            };
            
            const response = await s3.listObjectsV2(params).promise();
            const urls = response.Contents
              .filter(file => file.Key.endsWith('.jpg') || file.Key.endsWith('.jpeg') || file.Key.endsWith('.png') || file.Key.endsWith('.JPG')) // Filter only image files
              .map(file => {
                return `https://${S3_BUCKET_NAME}.s3.${REGION}.amazonaws.com/${file.Key}`;
              });
              
            this.setState({ dulaangImageUrls: urls })
            console.log(urls)
          } catch (error) {
            console.log('Error listing files:', error);
        }
      };
  
      getUrls();
    }

    getPhotos(division) {
        let photosList = []
        if (division === 'rpc') {
            photosList = this.state.rpcImageUrls
        } else if (division === 'rpcpa') {
            photosList = this.state.rpcpaImageUrls
        } else {
            photosList = this.state.dulaangImageUrls
        }

        return (
            <div className='image-container'>
                {photosList.map((url, index) => (
                <a href={url} target="_blank">
                  <div
                    key={index}
                    style={{
                      position:'relative',
                      display: 'inline-block',
                      backgroundImage: `url(${url})`,
                      backgroundSize: 'cover',
                      margin:'5px',
                      backgroundRepeat: 'no-repeat',
                      width: '300px',
                      height: '300px',
                    }}
                  ></div>
                </a>
                ))}
            </div>
        )
    }


    render() {

      return (
        <div id="photos-page-div">
          <div className='photos-selector-button' onClick={()=>this.setState({ currentDivision:'rpc' })}>
              <h4>Richmond Pilipino Class</h4>
          </div>
          <div className='photos-selector-button' onClick={()=>this.setState({ currentDivision:'rpcpa' })}>
              <h4>RPC: Performing Arts</h4>
          </div>
          <div className='photos-selector-button' onClick={()=>this.setState({ currentDivision:'dulaang' })}>
              <h4>Dulaang Pilipino</h4>
          </div>

          {this.getPhotos(this.state.currentDivision)}
        </div>
      )
        
        // if (this.state.currentDivision === 'rpc') {
        //     return (
        //         <div>
                    
        //             {this.getPhotos('rpc')}
        //         </div>
        //     )
        // } else if (this.state.currentDivision === 'rpcpa') {
        //     return (
        //         <div>
        //             <h1>Test</h1>
        //             {this.getPhotos('rpcpa')}
        //         </div>
        //     )
        // } else {
        //     return (
        //         <div>
        //             {this.getPhotos('dulaang')}
        //         </div>
        //     )
        // }


    }
}